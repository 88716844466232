import { graphql, Link, useStaticQuery } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import React from "react";
import Layout from "../components/Layout";
import IconsSections from "../components/productPages/IconsSections";
import VideoWithTitleAndButton from "../components/productPages/VideoWithTextAndButton";
import Hero from "../components/shared/Hero/Hero";
import unescape from "../utils/usescape";
import TestIcon from "../components/productPages/assets/test.inline.svg";
import MinuteIcon from "../components/productPages/assets/minute.inline.svg";
import AutomatedIcon from "../components/productPages/assets/automated.inline.svg";
import AffordableIcon from "../components/productPages/assets/affordable.inline.svg";
import TextWithTitleAndButton from "../components/shared/TextWithTitleAndButton/TextWithTitleAndButton";
import ColumnWithImage from "../components/productPages/ColumnWithImage";
import { styled } from "../stitches/stitches.config";
import CheckMark from "../images/checkMark.inline.svg";
import Newsletter from "../components/shared/Newsletter/Newsletter";
import NextStep from "../components/shared/NextSteps/NextSteps";

import {
    detailSmall,
    paragraphTextSmall,
} from "../stitches/combinedStyles";

const seo = {
    title: "PLX-HotStage",
};

const hero = {
    title: "Superhot mechanical testing",
    byline:
        "The PLX-HotStage add-on module for our benchtop\ntesting device that extracts mechanical\nproperties at temperatures of up-to 800°C",
};

const videoWithTextAndButtonTheDreamTeam = {
    title: "The dream team",
    text:
        "The PLX-HotStage has been designed as a modular attachment to the existing Indentation        PLX-Benchtop - it does not require a whole new testing machine to be purchased!        The module is easily attached or removed from the benchtop machine,        dramatically increasing testing flexibility for our users.",
    video:
        "https://player.vimeo.com/video/928327846?autoplay=1&loop=1&title=0&byline=0&portrait=0&pip=0&background=1&autopause=0",
    button: {
        path: "/benchtop",
        text: "See the PLX-Benchtop",
    },
    columnTextOrder: 1
};

const videoWithTextAndButtonActionableDataFast = {
    title: "Actionable data, fast",
    text:
        "The testing process - from sample placement, heating, and testing is fully automated, requiring only a single click. The test is also extremely fast, removing the testing bottleneck from high temperature material research and development.",
    video:
        "https://player.vimeo.com/video/927579817?autoplay=1&loop=1&muted=1&title=0&byline=0&portrait=0&pip=0&background=1&autopause=0",
    // button: {
    //     path: "/request-a-quote",
    //     text: "Request a quote",
    // },
    columnTextOrder: 0
};

const icons = [
    {
        icon: <TestIcon />,
        title: "Test up to 800°C",
        description:
            "Test metallic parts at high temperatures",
    },
    {
        icon: <MinuteIcon />,
        title: "10 minute installation",
        description:
            "Modular. Swap in and swap out with just an allen key",
    },
    {
        icon: <AutomatedIcon />,
        title: "100% fully automated",
        description:
            "Automated procedure ensures reproducible results",
    },
    {
        icon: <AffordableIcon />,
        title: "Highly affordable",
        description:
            "High temperature testing without huge testing costs",
    },
];

const actionableDataFast = {
    title: "Actionable data, fast",
    description: [
        "The testing process - from sample placement, heating, and testing is fully automated, requiring only a single click. The test is also extremely fast, removing the testing bottleneck from high temperature material research and development.",
    ],
    secondaryButton: {
        text: "Request a quote",
        path: "/request-a-quote",
    }
};

const bigDataFromSmallThings = {
    title: "Big data from\nsmall things",
    description: [
        "The PLX-HotStage allows high temperature stress-strain curves to be measured on small volumes of material.\nThis unlocks entirely new insights for these traditionally difficult-to-test samples whilst minimising material wastage.",],
    point: [
        "Minimum sample size\n3 mm thick and 10 mm lateral",
        "Maximum sample size\n9 mm thick and 25 mm lateral\n\n\n"
    ],
    // secondaryButton: {
    //     text: "Download tech spec",
    //     path: "/assets/hot-pip-brochure.pdf",
    //     tier: "bordered" as const,
    //     padding: true,
    //     download: true
    // }
};

function Hotpip() {
    const data = useStaticQuery(graphql`
    query {
        background: file(relativePath: { eq: "hero_hotpip_background.png" }) {
            childImageSharp {
              gatsbyImageData(width: 800, placeholder: TRACED_SVG)
            }
          }
          benchtop: file(relativePath: { eq: "hotpip_render_v3.png" }) {
            childImageSharp {
              gatsbyImageData(width: 1920, placeholder: TRACED_SVG)
            }
          }
          mobileImage: file(relativePath: { eq: "hotpip_render_mobile.png" }) {
            childImageSharp {
              gatsbyImageData(width: 1920, placeholder: TRACED_SVG)
            }
          }
      corsica: file(relativePath: { eq: "hand_holding_small_sample.png" }) {
        childImageSharp {
          gatsbyImageData(width: 1100, placeholder: TRACED_SVG)
        }
      }
    }
  `);

    const bg = getImage(data.background);
    const image = getImage(data.benchtop);
    const mobileImage = getImage(data.mobileImage);
    const light = false;

    return (
        <Layout seo={seo} backgroundColor="white" backgroundColorUnderneath="white">

            <StyledDiv>
                <Hero
                    title={unescape(hero.title)}
                    bylineMaxWidth={412}
                    secondaryButton={{
                        text: "Request a quote",
                        path: "/request-a-quote",
                    }}
                    byline={hero.byline}
                    image={image}
                    mobileImage={image}
                    bg={undefined}
                    light={light}
                    page="hotpip"
                />
            </StyledDiv>
            <Container>
                <VideoWithTitleAndButton {...videoWithTextAndButtonTheDreamTeam} />
                <StyledIconsSections>
                    <IconsSections icons={icons} />
                </StyledIconsSections>
                <WrapperVideoWithTitleAndButton>
                    <VideoWithTitleAndButton {...videoWithTextAndButtonActionableDataFast} />
                </WrapperVideoWithTitleAndButton>
                <StyledColumnWithImage
                    image={getImage(data.corsica)}
                    imageAlt="Big data from small things"
                    reverse={true}
                >
                    <StyledTextWithTitleAndButton
                        title={bigDataFromSmallThings.title}
                       // button={bigDataFromSmallThings.secondaryButton}
                    >
                        {bigDataFromSmallThings.description}
                        <IconsWrapper>
                            {bigDataFromSmallThings.point.map((text, i) => (
                                <IconWrapper key={i}>
                                    <CheckMark />
                                    <IconsText>{text}</IconsText>
                                </IconWrapper>
                            ))}
                        </IconsWrapper>
                    </StyledTextWithTitleAndButton>
                </StyledColumnWithImage>
                <NewsletterWrapper>
                    <Newsletter />
                </NewsletterWrapper>
                <NextStep />
            </Container>
        </Layout>
    );
}
const WrapperVideoWithTitleAndButton = styled("div", {
    backgroundColor: "transparent",
    "@md": {
        backgroundColor: "transparent",
    },
    "h4": {
        marginTop: 0,
        paddingTop: 34
    }
})

const Container = styled("div", {
    margin: "auto",
    maxWidth: 1200,
    "@md": {
        width: "95%",
    },
});

const StyledTextWithTitleAndButton = styled(TextWithTitleAndButton, {
    whiteSpace: 'pre-line',
    zIndex: 99,
    p: {
        "@md": { maxWidth: 484 },
    },
    h4: {
        "@md": { maxWidth: 360 },
    },
});

const StyledColumnWithImage = styled(ColumnWithImage, {
    "& .gatsby-image-wrapper": {
        transform: "translateX(2%) translateY(13%) scale(1.04)",
    },
});

const StyledDiv = styled("div", {
    "& .gatsby-image-wrapper": {
       /* transform: "translate(0%, 0%)",
        width: 800,
        maxWidth: "85%", */
    },
    h1: {
        "@md": { maxWidth: 390 },
    },
});

const IconsWrapper = styled("span", {
    display: "block",
    marginTop: 27,
});

const IconWrapper = styled("span", {
    display: "flex",
    alignItems: "flex-start",
    marginBottom: 20,
    svg: {
        width: 32,
        height: 32,
    },
});

const IconsText = styled("span", {
    ...detailSmall,
    marginLeft: 6,
    whiteSpace: "pre-line",
    color: "$black100",
    "@md": {
        ...paragraphTextSmall,
        color: "$black100",
        marginLeft: 12,
        fontWeight: 600,
        fontSize: 20,
        lineHeight: "26px",
        letterSpacing: -0.36,
    },
});

const IconImageContainer = styled("div", {
    svg: {
        width: 64,
        height: 64,
    },
    "@lg": {
        svg: {
            width: 80,
            height: 80,
        },
    },
});

const NewsletterWrapper = styled("div", {
    "@md": { paddingBottom: 0 },
}
);

const StyledIconsSections = styled("div", {
    ".iconsContainer": {
        paddingBottom: 48,
        paddingTop: 48,
        border: "none",
        "@md": {
            borderBottom: "1px solid #D9D9D9",
            borderTop: "1px solid #D9D9D9",
        },
    }
});

export default Hotpip;