import React, { ReactNode } from "react";
import {
    heading100,
    heading150,
    heading200,
    heading300,
    heading50,
    detailSmall,
    paragraphTextMedium,
} from "../../stitches/combinedStyles";
import { styled } from "../../stitches/stitches.config";
import Button, { ButtonProps } from "../shared/Button/Button";
import { Link } from "gatsby";

interface VideoWithTextAndButtonProps {
    title: string;
    text: ReactNode;
    video: string;
    button?: {
        text: string;
        path: string;
        tier?: ButtonProps["tier"];
        padding?: boolean;
    };
    columnTextOrder: number
}

const VideoWithTextAndButton = ({ title, text, video, button, columnTextOrder }: VideoWithTextAndButtonProps) => {

    return (
        <Container>
            <TextWrapper style={{ order: columnTextOrder }}>
                <Title>{title}</Title>
                <Text>{text}</Text>
               
                    {button && (
                        <ButtonsContainer>
                            <Link to={button.path}>
                                <Button
                                    size="medium"
                                    tier="bordered"
                                    arrow>
                                    {button.text}
                                </Button>
                            </Link>
                        </ButtonsContainer>
                    )}
               
            </TextWrapper>
            <VideoWrapperDesktop style={{ order: (columnTextOrder === 0) ? 1 : 0 }}>
                <Video
                    src={video}
                    height="100%"
                    frameBorder="0"
                //allow="autoplay; fullscreen;"
                />
            </VideoWrapperDesktop>
           
        </Container>
    );
};

export default VideoWithTextAndButton;

const Container = styled("div", {
    maxWidth: 800,
    width: "90%",
    margin: "auto",
    paddingBottom: 0,
    paddingTop: 0,
    textAlign: "left",
    "@lg": {
        display: "flex",
        width: "95%",
        maxWidth: 1300,
        paddingTop: 75,
        paddingBottom: 75,
        justifyContent: "space-between",
        alignItems: "center",
    },
});

const VideoWrapperDesktop = styled("div", {
    position: "relative",
    flex: "1 0 50%",
    borderRadius: 4,
    border: "1px solid transparent",
    "@md": {
        display: "block",
        overflow: "hidden",
        width: 578,
        height: 335,
    },
});

const Video = styled("iframe", {
    top: 0,
    left: 0,
    aspectRatio: " 97 / 56",
    border: "1px solid transparent",
    width: "100%",
    
    "@md": {
        borderRadius: 4,
        width: 580,
        height: 337,
    },
});

const TextWrapper = styled("div", {
    ...heading50,
    padding: 16,
    paddingTop: 0,
    "@sm": {
        ...heading100,
        paddingTop: 16,
        paddingBottom: 32,
    },
    "@md": {
        ...heading150,
        padding: 32,
    },
    "@lg": {
        ...heading200,
        padding: 64,
        maxWidth: 550,
        letterSpacing: -0.577,
    },
});

const Title = styled("h4", {
    ...heading100,
    marginBottom: 24,
    "@lg": {
        ...heading300,
        letterSpacing: -0.76,
        marginTop: 0,
    },
});
const ButtonsContainer = styled("div", {
    alignItems: "center",
    display: "flex",
    marginBottom: "45px",
    "@md": {
        marginBottom: "initial",
    },
});

const DesktopContainer = styled("div", {
    display: "none",
    "@md": {
        display: "block",
    },
})

const MobileContainer = styled("div", {
    display: "block",
    "@md": {
        display: "none",
    },
})

const Text = styled("p", {
    ...detailSmall,
    "@md": {
        ...paragraphTextMedium
    },
   
})